import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface stepState {
  activeStep: number;
}
// valorizzo stato iniziale
const initialState: stepState = {
  activeStep: 1,
};

export const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setActiveStep: (state, action: PayloadAction<number>) => {
      state.activeStep = action.payload;
    },
    resetAllStep: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setActiveStep, resetAllStep } = stepSlice.actions;

export default stepSlice.reducer;
