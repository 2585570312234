import { RootState } from "@/lib/store";
import { LicenseData } from "@/types/license";
import { User } from "@/types/login";
import { MCUSeriesResultData } from "@/types/mcu";
import {
  DownloadedSDK,
  GenerateSDKData,
  InternalGenerateSDKData,
} from "@/types/sdk";
import { SearchResultData } from "@/types/search";
import { SoftwareListData } from "@/types/software";
import { SubscriptionKeysItem } from "@/types/subscriptionKeys";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
    credentials: "same-origin",
    prepareHeaders: (headers, { getState }) => {
      const { traceId } = getState() as RootState;

      headers.set("trace-Id", traceId.lastTraceId);

      return headers;
    },
  }),
  tagTypes: ["SubscriptionKeys", "ToolList", "SecurityRoleList", "RoleList"],
  endpoints: (builder) => ({
    getMCUSeries: builder.query<MCUSeriesResultData, void>({
      query: () => ({
        url: "/sdk/api/v1/fe/subclass/series",
        method: "GET",
      }),
    }),
    // NOTE search improvement
    getSearchedValue: builder.query<SearchResultData[], string>({
      query: (rootPartNumber) => ({
        url: `/sdk/api/v1/fe/search?rootpartnumber=${encodeURIComponent(
          rootPartNumber
        )}`,
        method: "GET",
      }),
    }),
    getSoftwareList: builder.query<SoftwareListData, string>({
      query: (seriesId) => ({
        url: `/sdk/api/v1/fe/series/${seriesId}`,
        method: "GET",
      }),
    }),
    getLoginData: builder.query<User, void>({
      query: () => ({
        url: "/sdk/api/v1/login/validate",
        method: "GET",
      }),
    }),
    // NOTE testo per licenza
    getLicense: builder.query<LicenseData, void>({
      query: () => ({
        url: "/sdk/api/v1/fe/license/1",
        method: "GET",
      }),
    }),
    // NOTE Accettazione licenza 1
    getGenerateSDK: builder.query<
      GenerateSDKData,
      {
        softwareList: Array<number>;
      }
    >({
      query: ({ softwareList }) => ({
        url: "/sdk/api/v1/fe/generatesdk",
        method: "POST",
        body: JSON.stringify({
          softwareListIds: softwareList,
        }),
      }),
    }),
    // NOTE api per fare polling fine creazione SDK
    getStatusSDK: builder.query<InternalGenerateSDKData, string>({
      query: (traceId) => ({
        url: `/sdk/api/v1/fe/sdkgetstatus/${encodeURIComponent(traceId)}`,
        method: "GET",
      }),
    }),
    subscriptionKeysGenerate: builder.mutation<string, void>({
      query: () => ({
        url: "/sdk/api/v1/fe/subscription/generate",
        method: "POST",
      }),
      invalidatesTags: [{ type: "SubscriptionKeys" }],
    }),
    getSubscriptionKeysList: builder.query<SubscriptionKeysItem[], void>({
      query: () => ({
        url: "/sdk/api/v1/fe/subscription/list",
        method: "GET",
      }),
      providesTags: ["SubscriptionKeys"],
    }),
    subscriptionKeysDelete: builder.mutation<string, string>({
      query: (displayName) => ({
        url: `/sdk/api/v1/fe/subscription/delete/${displayName}`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "SubscriptionKeys" }],
    }),
    // NOTE download SDK pack
    downloadSDK: builder.query<
      DownloadedSDK,
      {
        traceId: string;
      }
    >({
      query: ({ traceId }) => ({
        url: `/sdk/api/v1/fe/download/${traceId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetMCUSeriesQuery,
  useGetSearchedValueQuery,
  useGetSoftwareListQuery,
  useGetLoginDataQuery,
  useGetLicenseQuery,
  useGetGenerateSDKQuery,
  useGetStatusSDKQuery,
  useSubscriptionKeysGenerateMutation,
  useGetSubscriptionKeysListQuery,
  useSubscriptionKeysDeleteMutation,
  useDownloadSDKQuery,
} = apiSlice;
