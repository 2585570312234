import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface pathState {
  prevUrl: string;
}
// valorizzo stato iniziale
const initialState: pathState = {
  prevUrl: "",
};

export const pathSlice = createSlice({
  name: "path",
  initialState,
  reducers: {
    setPrevUrl: (state, action: PayloadAction<string>) => {
      state.prevUrl = action.payload;
    },
    resetAllPath: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setPrevUrl, resetAllPath } = pathSlice.actions;

export default pathSlice.reducer;
