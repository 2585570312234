import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface errorState {
  errorCode: number | null;
  errorDescription: string;
}
// valorizzo stato iniziale
const initialState: errorState = {
  errorCode: null,
  errorDescription: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setErrorCode: (state, action: PayloadAction<number>) => {
      state.errorCode = action.payload;
    },
    setErrorDescription: (state, action: PayloadAction<string>) => {
      state.errorDescription = action.payload;
    },
    resetAllError: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setErrorCode, setErrorDescription, resetAllError } =
  errorSlice.actions;

export default errorSlice.reducer;
