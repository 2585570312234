import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface licenseState {
  acceptLicense: boolean;
}
// valorizzo stato iniziale
const initialState: licenseState = {
  acceptLicense: false,
};

export const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    setAcceptLicense: (state, action: PayloadAction<boolean>) => {
      state.acceptLicense = action.payload;
    },
    resetAllLicense: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setAcceptLicense, resetAllLicense } = licenseSlice.actions;

export default licenseSlice.reducer;
