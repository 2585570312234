import { SearchResultData } from "@/types/search";
import { MCUItem } from "@/types/mcu";
import { ApiError } from "@/types/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface tableState {
  selectedRow: MCUItem | SearchResultData | undefined;
  error: ApiError | undefined;
}

const initialState: tableState = {
  selectedRow: undefined,
  error: undefined,
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<MCUItem | SearchResultData>) => {
      state.selectedRow = action.payload;
    },
    setError: (state, action: PayloadAction<ApiError | undefined>) => {
      state.error = action.payload;
    },
    resetAllTable: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { add, setError, resetAllTable } = tableSlice.actions;

export default tableSlice.reducer;
