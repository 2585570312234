import { UserManagement } from "./../../components/userManagement/UserManagement";
import { ToolData } from "@/types/tool";
import { UserRoleData } from "@/types/userRole";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ApiError } from "@/types/types";

// dichiaro stato
export interface UserManagementState {
  selectedUser: UserRoleData | null;
  searchedUsers: UserRoleData[] | null;
  searchError: ApiError | null;
  inputSearchValue: string;
  userTableError: ApiError | null;
}
// valorizzo stato iniziale
const initialState: UserManagementState = {
  selectedUser: null,
  searchedUsers: null,
  searchError: null,
  inputSearchValue: "",
  userTableError: null,
};

export const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setSelectedUser: (state, action: PayloadAction<UserRoleData | null>) => {
      state.selectedUser = action.payload;
    },
    setSearchUsers: (state, action: PayloadAction<UserRoleData[]>) => {
      state.searchedUsers = action.payload;
    },
    resetSearchUser: (state) => {
      state.searchedUsers = null;
    },
    setUserSearchError: (state, action: PayloadAction<ApiError | null>) => {
      state.searchError = action.payload;
    },
    setUserInputSearchValue: (state, action: PayloadAction<string>) => {
      state.inputSearchValue = action.payload;
    },
    setUserTableError: (state, action: PayloadAction<ApiError | null>) => {
      state.userTableError = action.payload;
    },
    resetAllUserSearch: (state) => {
      state.searchedUsers = null;
      state.searchError = null;
      state.inputSearchValue = "";
    },
    resetAllUser: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedUser,
  setSearchUsers,
  resetSearchUser,
  setUserSearchError,
  setUserInputSearchValue,
  setUserTableError,
  resetAllUserSearch,
  resetAllUser,
} = userManagementSlice.actions;

export default userManagementSlice.reducer;
