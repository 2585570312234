import { Software } from "@/types/software";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface softwareState {
  softwareSelectedCube1: Software[];
  softwareSelectedCube2: Software[];
  buildPackages: boolean;
  activeTab: number;
  softwareList: Software[];
}

const initialState: softwareState = {
  softwareSelectedCube1: [],
  softwareSelectedCube2: [],
  buildPackages: false,
  activeTab: 1,
  softwareList: [],
};

const containsObject = (obj: Software, list: Software[]) => {
  if (list) {
    return list.some((item) => item.softwareId === obj.softwareId);
  }
};

export const softwareSlice = createSlice({
  name: "software",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Software[]>) => {
      action.payload.map((software) => {
        if (software.cubeVersion === 1) {
          if (
            state.softwareSelectedCube1 &&
            !containsObject(software, state.softwareSelectedCube1)
          ) {
            state.softwareSelectedCube1.push(software);
          }
        } else {
          if (
            state.softwareSelectedCube2 &&
            !containsObject(software, state.softwareSelectedCube2)
          ) {
            state.softwareSelectedCube2.push(software);
          }
        }
      });
    },
    toggle: (state, action: PayloadAction<Software>) => {
      const cubeVersion = action.payload.cubeVersion;
      const targetState =
        cubeVersion === 1 ? "softwareSelectedCube1" : "softwareSelectedCube2";
      let dynamicState = state[targetState];

      const existingIndex = dynamicState.findIndex(
        (item) =>
          item.softwareId === action.payload.softwareId &&
          item.cubeVersion === action.payload.cubeVersion
      );

      if (existingIndex !== -1) {
        dynamicState[existingIndex].isChecked = false;
        dynamicState.splice(existingIndex, 1);
      } else {
        dynamicState.push({ ...action.payload, isChecked: true });
      }
    },
    resetTab: (state) => {
      const includeItemCube1 =
        state.softwareSelectedCube1 &&
        state.softwareSelectedCube1.filter((item) => item.suggested === true);
      const includeItemCube2 =
        state.softwareSelectedCube2 &&
        state.softwareSelectedCube2.filter((item) => item.suggested === true);

      state.softwareSelectedCube1 = includeItemCube1;
      state.softwareSelectedCube2 = includeItemCube2;
    },
    toggleBuild: (state) => {
      state.buildPackages = !state.buildPackages;
    },
    setActiveTab: (state, action: PayloadAction<number>) => {
      state.activeTab = action.payload;
    },
    setSoftwareList: (state, action: PayloadAction<Software[]>) => {
      state.softwareList = action.payload;
    },
    resetAllSoftware: () => initialState,
  },
});

export const {
  add,
  toggle,
  resetTab,
  toggleBuild,
  setActiveTab,
  setSoftwareList,
  resetAllSoftware,
} = softwareSlice.actions;

export default softwareSlice.reducer;
