import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface loginState {
  email: string | null;
}
// valorizzo stato iniziale
const initialState: loginState = {
  email: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    resetAllLogin: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setEmail, resetAllLogin } = loginSlice.actions;

export default loginSlice.reducer;
