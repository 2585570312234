import type { AppProps } from "next/app";
import "../common/styles/main.scss";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "@radix-ui/themes/styles.css";
import { persistor, store } from "@/lib/store";

export default function MyApp({ Component, pageProps }: AppProps<{}>) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Component {...pageProps} />
      </PersistGate>
    </Provider>
  );
}
