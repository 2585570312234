import { ToolData } from "@/types/tool";
import { ApiError } from "@/types/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface toolDownloadState {
  toolList: ToolData[];
  selectedTool: ToolData | null;
  toolTableError: ApiError | null;
}
// valorizzo stato iniziale
const initialState: toolDownloadState = {
  toolList: [],
  selectedTool: null,
  toolTableError: null,
};

export const toolDownloadSlice = createSlice({
  name: "toolDownload",
  initialState,
  reducers: {
    setToolList: (state, action: PayloadAction<ToolData[]>) => {
      state.toolList = action.payload;
    },
    setSelectedTool: (state, action: PayloadAction<ToolData | null>) => {
      state.selectedTool = action.payload;
    },
    setToolTableError: (state, action: PayloadAction<ApiError | null>) => {
      state.toolTableError = action.payload;
    },
    resetAllTool: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setToolList, setSelectedTool, setToolTableError, resetAllTool } =
  toolDownloadSlice.actions;

export default toolDownloadSlice.reducer;
