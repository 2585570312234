import { SecurityRoleData } from "@/types/securityRoles";
import { ApiError } from "@/types/types";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// dichiaro stato
export interface securityRolesState {
  securityRoleList: SecurityRoleData[];
  selectedRole: SecurityRoleData | null;
  roleTableError: ApiError | null;
}
// valorizzo stato iniziale
const initialState: securityRolesState = {
  securityRoleList: [],
  selectedRole: null,
  roleTableError: null,
};

export const securityRolesSlice = createSlice({
  name: "securityRoles",
  initialState,
  reducers: {
    setSecurityRoleList: (state, action: PayloadAction<SecurityRoleData[]>) => {
      state.securityRoleList = action.payload;
    },
    setSelectedRole: (state, action: PayloadAction<SecurityRoleData | null>) => {
      state.selectedRole = action.payload;
    },
    setRoleTableError: (state, action: PayloadAction<ApiError | null>) => {
      state.roleTableError = action.payload;
    },
    resetAllRoles: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setSecurityRoleList,
  setSelectedRole,
  setRoleTableError,
  resetAllRoles } = securityRolesSlice.actions;

export default securityRolesSlice.reducer;
